/* eslint-disable import/no-anonymous-default-export */
export default {
  ABOUT_US: "/about-us",
  BYLAWS: "/bylaws",
  DEFAULT: "/",
  DASHBOARD: {
    ACCOUNT_SETTINGS: "/dashboard/account-settings",
    BYLAWS: "/dashboard/bylaws",
    DEFAULT: "/dashboard",
    HELP: "/dashboard/help",
    INVOLVEMENT: "/dashboard/involvement",
  },
  EVENTS: "/events",
  GET_INVOLVED: "/get-involved",
  LOGIN: "/login",
  OUR_VISION: "/our-vision",
};
