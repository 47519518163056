import CampaignIcon from "@mui/icons-material/Campaign";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GavelIcon from "@mui/icons-material/Gavel";
import GroupIcon from "@mui/icons-material/Group";
import HelpIcon from "@mui/icons-material/Help";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import RoutesConst from "../../consts/RoutesConst.js";
import GeneralStrings from "../../strings/GeneralStrings.json";

const NAV_STRINGS = GeneralStrings.DASHBOARD.NAV;

export const dashboardItem = (
  <>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.DEFAULT}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.DASHBOARD} />
    </ListItem>
  </>
);

export const applicationItems = (
  <>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.BYLAWS}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <GavelIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.BYLAWS} />
    </ListItem>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.INVOLVEMENT}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <CampaignIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.INVOLVEMENT} />
    </ListItem>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.USERS}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <GroupIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.USERS} />
    </ListItem>
  </>
);

export const utilitiesItems = (
  <>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.ACCOUNT_SETTINGS}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <SettingsSuggestIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.ACCOUNT_SETTINGS} />
    </ListItem>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.HELP}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <HelpIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.HELP} />
    </ListItem>
  </>
);
