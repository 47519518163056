import { Table } from "@mui/joy";
import { Alert, Button, Grid, Paper, Snackbar } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import DashboardSubPageToolbar from "../../../components/DashboardSubPageToolbar/DashboardSubPageToolbar";
import GeneralConst from "../../../consts/GeneralConst";
import AWSService from "../../../services/AWSService";
import BoardMembersService from "../../../services/BoardMembersService";
import AlertStrings from "../../../strings/AlertStrings.json";
import GeneralStrings from "../../../strings/GeneralStrings.json";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import AddBoardMemberModal from "./AddBoardMemberModal/AddBoardMemberModal";

const Involvement = () => {
  const [showToast, setShowToast] = useState(false);
  const [deleteValue, setDeleteValue] = useState(null);
  const [boardMembers, setBoardMembers] = useState([]);
  const [deleteImgLoc, setDeleteImgLoc] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showAddBoardMemberModal, setShowAddBoardMemberModal] = useState(false);
  const [showDeleteBoardMemberModal, setShowDeleteBoardMemberModal] =
    useState(false);
  const STATUS_CODES = GeneralConst.STATUS_CODES;
  const involvementStrings = GeneralStrings.DASHBOARD.INVOLVEMENT;

  const toggleAddBoardMemberModal = () =>
    setShowAddBoardMemberModal(!showAddBoardMemberModal);
  const toggleDeleteBoardMemberModal = () =>
    setShowDeleteBoardMemberModal(!showDeleteBoardMemberModal);

  const deleteBoardMember = (event) => {
    setDeleteValue(event.target.getAttribute("data-value") || null);
    setDeleteImgLoc(event.target.getAttribute("data-imgloc") || null);
    toggleDeleteBoardMemberModal();
  };

  const addBoardMember = (event) => {
    event.preventDefault();
    toggleAddBoardMemberModal();
  };

  // TODO: Figure out while delete function is not deleting the image
  const handleDelete = (formData) => {
    const { deleteValue, secondaryDeleteValue } = formData;

    if (secondaryDeleteValue !== GeneralConst.EVENT_DEFAULT_IMAGE) {
      AWSService.deleteBoardMemberPic(secondaryDeleteValue)
        .then(() => {
          BoardMembersService.deleteBoardMember(deleteValue)
            .then((response) => {
              if (response.data.status === STATUS_CODES.ERROR) {
                setFailedMessage(response?.data?.statusMessage);
              } else {
                setSuccessMessage(AlertStrings.DELETE_BOARD_MEMBER_SUCCESS);
              }
            })
            .catch(() => {
              setFailedMessage(AlertStrings.DELETE_BOARD_MEMBER_ERROR);
            })
            .finally(() => {
              setShowToast(true);
              toggleDeleteBoardMemberModal();
            });
        })
        .catch(() => {
          setFailedMessage(AlertStrings.DELETE_IMAGE_ERROR);
          setShowToast(true);
          toggleDeleteBoardMemberModal();
        });
    } else {
      BoardMembersService.deleteBoardMember(deleteValue)
        .then((response) => {
          if (response.data.status === STATUS_CODES.ERROR) {
            setFailedMessage(response?.data?.statusMessage);
          } else {
            setSuccessMessage(AlertStrings.DELETE_BOARD_MEMBER_SUCCESS);
          }
        })
        .catch(() => {
          setFailedMessage(AlertStrings.DELETE_BOARD_MEMBER_ERROR);
        })
        .finally(() => {
          setShowToast(true);
          toggleDeleteBoardMemberModal();
        });
    }
  };

  useEffect(() => {
    BoardMembersService.getBoardMembers().then((response) => {
      const members = response?.data;
      setBoardMembers(members);
    });
  }, [showToast]);

  return (
    <DashboardLayout>
      <Grid container spacing={3} className="involvement">
        <DashboardSubPageToolbar
          title={involvementStrings.INVOLVEMENT}
          addButtonText={involvementStrings.ADD_BOARD_MEMBER}
          addFunction={addBoardMember}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          autoHideDuration={5000}
          onClose={() => setShowToast(false)}
        >
          <div className="dashboard-snackbar">
            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            {failedMessage && <Alert severity="error">{failedMessage}</Alert>}
          </div>
        </Snackbar>
        <Grid item xs={12}>
          <Paper className="dashboard-table-container" elevation={0}>
            <h4>{involvementStrings.BOARD_MEMBERS}</h4>
            <Table
              borderAxis="bothBetween"
              className="dashboard-table"
              color="primary"
              size="lg"
              stickyHeader
              hoverRow
              stripe="odd"
              variant="plain"
            >
              <thead>
                <tr>
                  <th style={{ width: "25%" }}>
                    {GeneralStrings.FORM.FIRST_NAME}
                  </th>
                  <th style={{ width: "25%" }}>
                    {GeneralStrings.FORM.LAST_NAME}
                  </th>
                  <th style={{ width: "20%" }}>{GeneralStrings.FORM.EMAIL}</th>
                  <th style={{ width: "20%" }}>
                    {GeneralStrings.FORM.POSITION}
                  </th>
                  <th style={{ width: "10%" }}></th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(boardMembers) &&
                  boardMembers.map((member) => (
                    <tr key={"member-" + member.id}>
                      <td className="ellipsis nowrap">{member.firstName}</td>
                      <td className="ellipsis nowrap">{member.lastName}</td>
                      <td className="ellipsis nowrap">{member.email}</td>
                      <td className="ellipsis nowrap">{member.position}</td>
                      <td className="flex flex-row jc-center ellipsis nowrap">
                        <Button
                          className="dashboard-button"
                          color="error"
                          variant="contained"
                          data-value={member.id}
                          data-imgloc={member.imgLoc}
                          onClick={deleteBoardMember}
                        >
                          {GeneralStrings.FORM.DELETE}
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Paper>
        </Grid>
        <AddBoardMemberModal
          isOpen={showAddBoardMemberModal}
          setFailedMessage={setFailedMessage}
          setShowToast={setShowToast}
          setSuccessMessage={setSuccessMessage}
          toggle={toggleAddBoardMemberModal}
        />
        <ConfirmDeleteModal
          deleteMessage={involvementStrings.DELETE_DESCRIPTION}
          handleDelete={handleDelete}
          isOpen={showDeleteBoardMemberModal}
          deleteValue={deleteValue}
          secondaryDeleteValue={deleteImgLoc}
          toggle={toggleDeleteBoardMemberModal}
        />
      </Grid>
    </DashboardLayout>
  );
};

export default Involvement;
