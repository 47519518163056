import Avatar from "@mui/joy/Avatar";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";
import { parsePhoneNumber } from "libphonenumber-js";
import React from "react";
import "./BoardMemberCard.scss";

const BoardMemberCard = ({ member }) => {
  return (
    <div className="board-member-card">
      <Card
        sx={{
          width: 320,
          maxWidth: "100%",
          boxShadow: "lg",
        }}
      >
        <CardContent sx={{ alignItems: "center", textAlign: "center" }}>
          <Avatar src={member?.imgLoc} sx={{ "--Avatar-size": "7rem" }} />
          <Chip
            size="lg"
            variant="soft"
            color="primary"
            sx={{
              mt: -1,
              mb: 1,
              border: "3px solid",
              borderColor: "background.surface",
            }}
          >
            {member?.position}
          </Chip>
          <Typography level="title-lg">{`${member?.firstName} ${member?.lastName}`}</Typography>
          <div className="contact-info">
            {member?.phoneNumber && (
              <p>{parsePhoneNumber(member.phoneNumber).formatNational()}</p>
            )}
            <p className="ellipsis nowrap">{member?.email}</p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default BoardMemberCard;
