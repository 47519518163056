import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer.jsx";
import HomepageNavbar from "../../components/HomepageNavbar/HomepageNavbar";
import GeneralConst from "../../consts/GeneralConst";
import BylawsService from "../../services/BylawsService";
import AlertStrings from "../../strings/AlertStrings.json";
import "./BylawsPage.scss";

const TITLE = `Bylaws | ${GeneralConst.SITE_NAME}`;

const BylawsPage = () => {
  const [bylaws, setBylaws] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [failedMessage, setFailedMessage] = useState(null);

  useEffect(() => {
    BylawsService.getBylaws()
      .then((response) => {
        setBylaws(response?.data);
      })
      .catch(() => {
        setFailedMessage(AlertStrings.GET_BYLAWS_ERROR);
        setShowToast(true);
      })
      .finally(() => {
        setShowToast(false);
      });
  }, []);

  /* TODO: Add a loading state for when bylaws have not loaded fully */

  return (
    <div className="bylaws-page">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HomepageNavbar transparentNavbar={false} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showToast}
        autoHideDuration={5000}
        onClose={() => setShowToast(false)}
      >
        <div className="dashboard-snackbar">
          {failedMessage && <Alert severity="error">{failedMessage}</Alert>}
        </div>
      </Snackbar>
      <div className="container">
        <div
          className="bylaws-container"
          dangerouslySetInnerHTML={{
            __html: bylaws,
          }}
        />
      </div>
      <Footer />
    </div>
  );
};

export default BylawsPage;
