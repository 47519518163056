import { Link } from "@mui/material";
import React from "react";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./CallToAction.scss";

const CallToAction = () => {
  const ctaStrings = GeneralStrings.CALL_TO_ACTION;

  return (
    <div class="cta-parallax text-center">
      <p className="cta-text">{ctaStrings.CTA_HEADER}</p>
      <Link
        href="https://givebutter.com/DbH5hy"
        target="_blank"
        className="cta-link"
      >
        {GeneralStrings.FORM.DONATE}
      </Link>
    </div>
  );
};

export default CallToAction;
