import emailjs from "@emailjs/browser";
import { Button } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import Recaptcha from "react-recaptcha";
import { Alert, Spinner } from "reactstrap";
import GeneralConst from "../../consts/GeneralConst";
import AlertStrings from "../../strings/AlertStrings.json";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./Contact.scss";

const Contact = () => {
  const [recaptchaLoad, setRecaptchaLoad] = useState(false);
  const [isVerified, setIsVerified] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sentMessage, setSentMessage] = useState(false);
  const [failedMessage, setFailedMessage] = useState(false);
  const contactStrings = GeneralStrings.CONTACT;

  const recaptchaLoaded = () => {
    setRecaptchaLoad(true);
  };

  const verifiedRecaptcha = (response) => {
    if (response) {
      setIsVerified(response);
    }
  };

  const resetErrorMessages = () => {
    setSentMessage(false);
    setFailedMessage(false);
  };

  const handleSubmit = (formValues) => {
    setIsLoading(true);
    resetErrorMessages();

    if (recaptchaLoad && isVerified) {
      const templateParams = {
        from_name: formValues.name,
        from_email: formValues.email,
        to_name: GeneralConst.TO_NAME,
        to_email: GeneralConst.TO_EMAIL,
        subject: formValues.subject,
        message_html: formValues.message,
        "g-recaptcha-response": isVerified,
      };

      emailjs
        .send(
          GeneralConst.EMAILJS_SERVICE,
          GeneralConst.EMAILJS_TEMPLATE,
          templateParams,
          process.env.REACT_APP_EMAIL_JS_KEY
        )
        .then(() => {
          setSentMessage(true);
        })
        .catch(() => {
          setFailedMessage(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    setIsLoading(false);
  };

  // TODO: Move Validators to helper function, clear out in other areas
  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email is Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid Email Address";
    }
    return error;
  }

  function validateName(value) {
    let error;
    if (value === null || value === "") {
      error = "Name is Required";
    }
    return error;
  }

  function validateSubject(value) {
    let error;
    if (value === null) {
      error = "Subject is Required";
    }
    return error;
  }

  function validateMessage(value) {
    let error;
    if (value === null) {
      error = "Message is Required";
    }
    return error;
  }

  return (
    <div className="section section-black bg-white text-white" id="contact">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-xs-12">
              <h2 className="contact-form-header">
                {contactStrings.DESCRIPTION}
              </h2>
            </div>

            <div className="col-lg-8 col-xs-12">
              <div className="contact-form col-md-12">
                {failedMessage && (
                  <Alert color="danger">{AlertStrings.CONTACT_ERROR}</Alert>
                )}

                {sentMessage && <Alert>{AlertStrings.CONTACT_SUCCESS}</Alert>}

                <Formik
                  initialValues={{
                    email: "",
                    name: "",
                    message: "",
                    subject: "",
                  }}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  <Form>
                    <div className="form-group">
                      <Field
                        name="name"
                        className="form-control input-color"
                        placeholder={GeneralStrings.FORM.FORM_LABELS.YOUR_NAME}
                        validate={validateName}
                      />
                      <ErrorMessage
                        component="span"
                        className="form-error"
                        name="name"
                      />
                    </div>

                    <div className="form-group">
                      <Field
                        name="email"
                        className="form-control input-color"
                        placeholder={GeneralStrings.FORM.FORM_LABELS.YOUR_EMAIL}
                        validate={validateEmail}
                      />
                      <ErrorMessage
                        component="span"
                        className="form-error"
                        name="email"
                      />
                    </div>

                    <div className="form-group">
                      <Field
                        name="subject"
                        className="form-control input-color"
                        placeholder={GeneralStrings.FORM.FORM_LABELS.SUBJECT}
                        validate={validateSubject}
                      />
                      <ErrorMessage
                        component="span"
                        className="form-error"
                        name="subject"
                      />
                    </div>

                    <div className="form-group">
                      <Field
                        as="textarea"
                        name="message"
                        className="form-control input-color"
                        placeholder={GeneralStrings.FORM.FORM_LABELS.MESSAGE}
                        validate={validateMessage}
                        rows={5}
                        type="textarea"
                      />
                      <ErrorMessage
                        component="span"
                        className="form-error"
                        name="message"
                      />
                    </div>

                    <div className="form-group">
                      <Recaptcha
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        render="explicit"
                        onloadCallback={recaptchaLoaded}
                        verifyCallback={verifiedRecaptcha}
                      />
                    </div>

                    <div className="form-group margin-top-20">
                      <Button
                        fullWidth
                        className="btn btn-primary btn-contact-submit"
                        type="submit"
                      >
                        <span className="submit-text">
                          {GeneralStrings.SUBMIT}
                        </span>
                        {isLoading && (
                          <Spinner color="dark" size="sm">
                            <span className="sr-only">
                              {GeneralStrings.FORM.FORM_LABELS.LOADING}
                            </span>
                          </Spinner>
                        )}
                      </Button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
