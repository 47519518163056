import { Component } from "react";
import { Route, Routes } from "react-router-dom";
import RoutesConst from "./consts/RoutesConst";
import AboutUsPage from "./pages/AboutUs/AboutUsPage";
import BylawsPage from "./pages/BylawsPage/BylawsPage";
import AccountSettings from "./pages/Dashboard/AccountSettings/AccountSettings";
import Dashboard from "./pages/Dashboard/Dashboard";
import HelpPage from "./pages/Dashboard/Help/HelpPage";
import Involvement from "./pages/Dashboard/Involvement/Involvement";
import EventsPage from "./pages/Events/EventsPage";
import GetInvolvedPage from "./pages/GetInvolvedPage/GetInvolvedPage";
import Homepage from "./pages/Homepage";
import LoginPage from "./pages/LoginPage/LoginPage";
import OurVisionPage from "./pages/OurVision/OurVisionPage";
export default class App extends Component {
  static displayName = App.name;

  // TODO: Nest Dashboard pages into Dashboard Route

  render() {
    return (
      <Routes>
        <Route index path={RoutesConst.DEFAULT} element={<Homepage />} />
        <Route index path={RoutesConst.ABOUT_US} element={<AboutUsPage />} />
        <Route
          index
          path={RoutesConst.OUR_VISION}
          element={<OurVisionPage />}
        />
        <Route index path={RoutesConst.BYLAWS} element={<BylawsPage />} />
        <Route
          index
          path={RoutesConst.GET_INVOLVED}
          element={<GetInvolvedPage />}
        />
        <Route index path={RoutesConst.LOGIN} element={<LoginPage />} />
        <Route
          index
          path={RoutesConst.DASHBOARD.DEFAULT}
          element={<Dashboard />}
        />
        {/* <Route index path={RoutesConst.DASHBOARD.BYLAWS} element={<Bylaws />} /> */}
        <Route
          index
          path={RoutesConst.DASHBOARD.INVOLVEMENT}
          element={<Involvement />}
        />
        <Route
          index
          path={RoutesConst.DASHBOARD.ACCOUNT_SETTINGS}
          element={<AccountSettings />}
        />
        <Route index path={RoutesConst.DASHBOARD.HELP} element={<HelpPage />} />
        <Route index path={RoutesConst.EVENTS} element={<EventsPage />} />
      </Routes>
    );
  }
}
