/* eslint-disable import/no-anonymous-default-export */
export default {
  EMAILJS_SERVICE: "service_9rdl8rv",
  EMAILJS_TEMPLATE: "template_dbj19j9",
  EVENT_CARD_SIZE: {
    NORMAL: "nm",
    SMALL: "sm",
  },
  LOGIN_FORM_TYPE: {
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    LOGIN: "LOGIN",
    RESET_PASSWORD: "RESET_PASSWORD",
  },
  SITE_NAME: "Running for Real Estate",
  STATUS_CODES: {
    DATA_ERROR: "DATA_ERROR",
    ERROR: "ERROR",
    PARTIAL_SUCCESS: "PARTIAL_SUCCESS",
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
  },
  TO_EMAIL: "victoryindy.bellamy@gmail.com",
  TO_NAME: "Running for Real Estate",
};
