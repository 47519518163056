import React from "react";
import logoTransparent from "../../images/logos/logoTransparent.png";
import "./TitleScreen.scss";

const TitleScreen = () => {
  return (
    <header class="title-screen">
      <div className="parallax">
        <div className="container-fluid">
          <div className="content">
            <div className="title-area">
              <img
                src={logoTransparent}
                className="title-screen-logo"
                alt="logo"
              />
            </div>
            <span className="title-arrow">
              <i className="far fa-arrow-alt-circle-down fa-5x animated bounce"></i>
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default TitleScreen;
