// TODO: Setup jsconfig for shorter directories
import { Helmet } from "react-helmet";
import CallToAction from "../components/CallToAction/CallToAction";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";
import HomepageNavbar from "../components/HomepageNavbar/HomepageNavbar";
import OurMission from "../components/OurMission/OurMission";
import Sponsors from "../components/Sponsors/Sponsors";
import TitleScreen from "../components/TitleScreen/TitleScreen";
import GeneralConst from "../consts/GeneralConst";

const TITLE = `Home | ${GeneralConst.SITE_NAME}`;

const Homepage = () => {
  return (
    <div className="homepage">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HomepageNavbar />
      <TitleScreen />
      <OurMission />
      <CallToAction />
      <Sponsors />
      <Contact />
      <Footer />
    </div>
  );
};

export default Homepage;
