import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import React, { useEffect, useState } from "react";
import AWSService from "../../services/AWSService";
import GeneralStrings from "../../strings/GeneralStrings.json";
import { getS3BucketItemURL } from "../../utilities/AWSHelper.js";
import "./Sponsors.scss";

const Sponsors = () => {
  const [sponsors, setSponsors] = useState([]);
  const sponsorsStrings = GeneralStrings.SPONSORS;

  useEffect(() => {
    AWSService.getAllSponsorImages().then((res) => {
      setSponsors(res?.Contents?.slice(1));
    });
  }, []);

  return (
    <div className="sponsors-section section container-fluid">
      <div className="row padding-top-50">
        <h2 className="header margin-bottom-50 text-center">
          {sponsorsStrings.SPONSORS}
        </h2>
        <div className="sponsor-row row">
          {sponsors &&
            sponsors.map((item, index) => {
              const itemURL = getS3BucketItemURL(item?.Key);

              return (
                <div className="sponsor-item col-sm-6 col-lg-4">
                  <Card
                    key={`image-${index}`}
                    sx={{ minHeight: 250, flexGrow: 1 }}
                  >
                    <AspectRatio
                      variant="soft"
                      sx={{
                        flexGrow: 1,
                        display: "contents",
                        "--AspectRatio-paddingBottom":
                          "clamp(0px, (100% - 360px) * 999, min(calc(100% / (16 / 9)), 300px))",
                      }}
                    >
                      <img
                        srcSet={itemURL}
                        src={itemURL}
                        alt={item?.Key}
                        loading="lazy"
                      />
                    </AspectRatio>
                  </Card>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Sponsors;
