const photoGalleryData = [
  {
    img: "https://runningforrealestate.s3.us-east-2.amazonaws.com/about-us/family-1.jpg",
    title: "Family",
  },
  {
    img: "https://runningforrealestate.s3.us-east-2.amazonaws.com/about-us/family-2.jpg",
    title: "Family",
  },
  {
    img: "https://runningforrealestate.s3.us-east-2.amazonaws.com/about-us/family-3.jpg",
    title: "Family",
  },
  {
    img: "https://runningforrealestate.s3.us-east-2.amazonaws.com/about-us/family-4.jpg",
    title: "Family",
  },
  {
    img: "https://runningforrealestate.s3.us-east-2.amazonaws.com/about-us/family-5.jpg",
    title: "Family",
  },
  {
    img: "https://runningforrealestate.s3.us-east-2.amazonaws.com/about-us/family-6.jpg",
    title: "Family",
  },
  {
    img: "https://runningforrealestate.s3.us-east-2.amazonaws.com/about-us/family-7.jpg",
    title: "Family",
  },
  {
    img: "https://runningforrealestate.s3.us-east-2.amazonaws.com/about-us/family-8.jpg",
    title: "Family",
  },
  {
    img: "https://runningforrealestate.s3.us-east-2.amazonaws.com/about-us/family-9.jpg",
    title: "Family",
  },
];

export default photoGalleryData;
