import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import GeneralConst from "../../../../consts/GeneralConst";
import AWSService from "../../../../services/AWSService.js";
import BoardMembersService from "../../../../services/BoardMembersService.js";
import AlertStrings from "../../../../strings/AlertStrings.json";
import GeneralStrings from "../../../../strings/GeneralStrings.json";
import { validateTextField } from "../../../../utilities/ValidationHelper.js";
import "./AddBoardMemberModal.scss";

const AddBoardMemberModal = ({
  isOpen,
  setFailedMessage,
  setShowToast,
  setSuccessMessage,
  toggle,
}) => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const STATUS_CODES = GeneralConst.STATUS_CODES;
  const involvementStrings = GeneralStrings.DASHBOARD.INVOLVEMENT;

  const handleFileChange = (e) => {
    const pic = e.target.files[0];
    setFile(pic);
  };

  const handleSubmit = (formValues) => {
    setIsLoading(true);

    if (file) {
      AWSService.uploadBoardMemberPic(
        file,
        `${formValues?.firstName}_${formValues?.lastName}`
      )
        .then((res) => {
          const imgLoc = res.Location;
          BoardMembersService.addBoardMember({ ...formValues, imgLoc })
            .then((response) => {
              if (response.data.status === STATUS_CODES.ERROR) {
                setFailedMessage(response?.data?.statusMessage);
              } else {
                setSuccessMessage(AlertStrings.ADD_BOARD_MEMBER_SUCCESS);
              }
            })
            .catch(() => {
              setFailedMessage(AlertStrings.ADD_BOARD_MEMBER_ERROR);
            })
            .finally(() => {
              setShowToast(true);
              setIsLoading(false);
              toggle();
            });
        })
        .catch(() => {
          setFailedMessage(AlertStrings.UPLOAD_IMAGE_FAIL);
          setIsLoading(false);
          toggle();
        });
    } else {
      BoardMembersService.addBoardMember({ ...formValues, imgLoc: "" })
        .then((response) => {
          if (response.data.status === STATUS_CODES.ERROR) {
            setFailedMessage(response?.data?.statusMessage);
          } else {
            setSuccessMessage(AlertStrings.ADD_BOARD_MEMBER_SUCCESS);
          }
        })
        .catch(() => {
          setFailedMessage(AlertStrings.ADD_BOARD_MEMBER_ERROR);
        })
        .finally(() => {
          setShowToast(true);
          setIsLoading(false);
          toggle();
        });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={toggle}
      className="add-board-member-modal"
    >
      <ModalHeader toggle={toggle}>
        {involvementStrings.ADD_BOARD_MEMBER}
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            position: "",
            phoneNumber: "",
            email: "",
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Form className="padding-left-50 padding-right-50 margin-bottom-25 ">
            <FormControl className="margin-bottom-35" required>
              <FormLabel>{GeneralStrings.FORM.FIRST_NAME}</FormLabel>
              <Field
                name="firstName"
                className="form-control"
                validate={validateTextField}
              />
              <ErrorMessage
                component="span"
                className="form-error"
                name="firstName"
              />
            </FormControl>
            <FormControl className="margin-bottom-35" required>
              <FormLabel>{GeneralStrings.FORM.LAST_NAME}</FormLabel>
              <Field
                name="lastName"
                className="form-control"
                validate={validateTextField}
              />
              <ErrorMessage
                component="span"
                className="form-error"
                name="lastName"
              />
            </FormControl>
            <FormControl className="margin-bottom-35" required>
              <FormLabel>{GeneralStrings.FORM.POSITION}</FormLabel>
              <Field
                name="position"
                className="form-control"
                validate={validateTextField}
              />
              <ErrorMessage
                component="span"
                className="form-error"
                name="position"
              />
            </FormControl>
            {/* TODO: Create phone number validator */}
            <FormControl className="margin-bottom-35">
              <FormLabel>{GeneralStrings.FORM.PHONE_NUMBER}</FormLabel>
              <Field
                type="phone"
                name="phoneNumber"
                className="form-control"
                validate={validateTextField}
              />
              <ErrorMessage
                component="span"
                className="form-error"
                name="phoneNumber"
              />
            </FormControl>
            <FormControl className="margin-bottom-35" /* required */>
              <FormLabel>{GeneralStrings.FORM.EMAIL}</FormLabel>
              <Field
                name="email"
                className="form-control input-color"
                /* validate={validateEmail} */
              />
              <ErrorMessage
                component="span"
                className="form-error"
                name="email"
              />
            </FormControl>
            <FormControl className="margin-bottom-35">
              <FormLabel>{GeneralStrings.FORM.PROFILE_PICTURE}</FormLabel>
              <Field
                name="imgLoc"
                accept="image/*"
                type="file"
                onChange={handleFileChange}
                className="form-control"
              />
              <ErrorMessage
                component="span"
                className="form-error"
                name="imgLoc"
              />
            </FormControl>
            <div className="flex flex-row">
              <Button
                type="submit"
                color="success"
                disabled={isLoading}
                className="text-lighter margin-left-auto"
              >
                {GeneralStrings.FORM.SUBMIT}
                {isLoading && (
                  <Spinner color="dark" size="sm" className="margin-left-10" />
                )}
              </Button>
            </div>
          </Form>
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default AddBoardMemberModal;
