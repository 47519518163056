import { Link } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import React from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer.jsx";
import HomepageNavbar from "../../components/HomepageNavbar/HomepageNavbar.jsx";
import GeneralConst from "../../consts/GeneralConst.js";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./AboutUsPage.scss";
import photoGalleryData from "./photo-gallery.js";

const TITLE = `About Us | ${GeneralConst.SITE_NAME}`;

const AboutUsPage = () => {
  const aboutUsStrings = GeneralStrings.ABOUT_US;

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HomepageNavbar transparentNavbar={false} />
      <div className="about-us" id="about-us">
        <div className="container section-container">
          <div className="row padding-top-50">
            <h2 className="header text-center">{aboutUsStrings.ABOUT_US}</h2>
            <p
              className="description"
              dangerouslySetInnerHTML={{
                __html: aboutUsStrings.DESCRIPTION_HTML,
              }}
            />

            <div className="photos-container">
              <ImageList variant="masonry" cols={isMobile ? 2 : 3} gap={8}>
                {photoGalleryData.map((item) => (
                  <Link href={item.img}>
                    <ImageListItem key={item.img}>
                      <img
                        srcSet={item.img}
                        src={item.img}
                        alt={item.title}
                        loading="lazy"
                      />
                    </ImageListItem>
                  </Link>
                ))}
              </ImageList>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUsPage;
