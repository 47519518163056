/* eslint-disable import/no-anonymous-default-export */
var AWS = require("aws-sdk");
const S3_BUCKET = "runningforrealestate";
const REGION = "us-east-2";

class AWSService {
  /**
   * [DELETE]
   * Deletes board member picture in S3 bucket
   * @param {String} fileName
   * @returns {Promise}
   */
  deleteBoardMemberPic(fileName) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: "board-members/" + fileName,
    };

    var deletionRequest = s3.deleteObject(params).promise();

    return deletionRequest;
  }

  /**
   * [DELETE]
   * Deletes file in S3 bucket
   * @param {String} fileName
   * @returns {Promise}
   */
  deleteProfilePic(fileName) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: "profile-pictures/" + fileName,
    };

    var deletionRequest = s3.deleteObject(params).promise();

    return deletionRequest;
  }

  /**
   * [GET]
   * Retrieves all sponsor images in S3 bucket
   * @returns {Promise}
   */
  getAllSponsorImages() {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    var upload = s3.listObjects({ Prefix: "sponsors" }).promise();

    return upload;
  }

  /**
   * [PUT]
   * Uploads board member picture into S3 bucket
   * @param {File} profilePic
   * @param {String} profilePicName
   * @returns {Promise}
   */
  uploadBoardMemberPic(boardMemberPic, boardMemberPicName) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const extention = `.${boardMemberPic.type.split("/").pop()}`;

    const params = {
      Bucket: S3_BUCKET,
      Key: `board-members/${boardMemberPicName}${extention}`,
      Body: boardMemberPic,
    };

    var upload = s3.upload(params).promise();

    return upload;
  }

  /**
   * [PUT]
   * Uploads profile picture into S3 bucket
   * @param {File} profilePic
   * @param {String} profilePicName
   * @returns {Promise}
   */
  uploadProfilePic(profilePic, profilePicName) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const extention = `.${profilePic.type.split("/").pop()}`;

    const params = {
      Bucket: S3_BUCKET,
      Key: `profile-pictures/${profilePicName}${extention}`,
      Body: profilePic,
    };

    var upload = s3.upload(params).promise();

    return upload;
  }
}

export default new AWSService();
