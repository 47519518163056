import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer.jsx";
import HomepageNavbar from "../../components/HomepageNavbar/HomepageNavbar";
import GeneralConst from "../../consts/GeneralConst";
import BoardMembersService from "../../services/BoardMembersService.js";
import GeneralStrings from "../../strings/GeneralStrings.json";
import BoardMemberCard from "./BoardMemberCard/BoardMemberCard.jsx";
import "./GetInvolvedPage.scss";

const TITLE = `Get Involved | ${GeneralConst.SITE_NAME}`;

const GetInvolvedPage = () => {
  const [boardMembers, setBoardMembers] = useState([]);
  const getInvolvedStrings = GeneralStrings.GET_INVOLVED;

  useEffect(() => {
    BoardMembersService.getBoardMembers().then((response) => {
      const members = response?.data;
      setBoardMembers(members);
    });
  }, []);

  return (
    <div className="get-involved-page">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HomepageNavbar transparentNavbar={false} />
      <div>
        <div className="container section-container">
          <div className="row padding-top-50">
            <h2 className="header text-center">
              {getInvolvedStrings.GET_INVOLVED}
            </h2>
            <p className="description">{getInvolvedStrings.DESCRIPTION}</p>
            <div className="col-sm-12 col-md-6">
              <img
                src="https://runningforrealestate.s3.us-east-2.amazonaws.com/get-involved/volunteer-1.png"
                alt="Volunteers"
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <img
                src="https://runningforrealestate.s3.us-east-2.amazonaws.com/get-involved/volunteer-2.png"
                alt="Volunteers"
              />
            </div>
            <hr />
            <div className="board-members-container container">
              <h2 className="sub-header margin-bottom-50 text-center">
                {getInvolvedStrings.BOARD_MEMBERS}
              </h2>
              <div className="row">
                {!_.isEmpty(boardMembers) &&
                  boardMembers?.map((member) => (
                    <div
                      className="col-sm-12 col-md-6 col-lg-4"
                      key={"member-" + member?.id}
                    >
                      <BoardMemberCard member={member} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GetInvolvedPage;
