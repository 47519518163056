import React from "react";
import houses from "../../images/backgrounds/houses-1.jpg";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./OurMission.scss";

const OurMission = () => {
  const ourMissionStrings = GeneralStrings.OUR_MISSION;

  return (
    <div className="our-mission">
      <div className="container section-container">
        <div className="row">
          <div className="col-md-12 col-lg-6 margin-vertical-center description-container">
            <span className="section-header">{ourMissionStrings.TITLE}</span>
            <p className="description">{ourMissionStrings.DESCRIPTION}</p>
          </div>
          <div className="col-md-12 col-lg-6 image-container">
            <img src={houses} alt="Our-Mission" className="our-mission-image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
