import { AppBar, IconButton } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import RoutesConst from "../../consts/RoutesConst";
import logoTransparent from "../../images/logos/logoTransparent.png";
import GeneralStrings from "../../strings/GeneralStrings";
import classNames from "../../utilities/classNames";
import "./HomepageNavbar.scss";
import MobileNavDrawer from "./MobileNavDrawer";

const HomepageNavbar = ({ transparentNavbar = true }) => {
  const [isTransparent, setIsTransparent] = useState(transparentNavbar);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navBarStrings = GeneralStrings.NAVBAR;
  const logoText = GeneralStrings.RUNNING_FOR_REAL_ESTATE;

  const handleTransparency = () => {
    if (window.scrollY >= 500) {
      setIsTransparent(false);
    } else {
      setIsTransparent(true);
    }
  };

  transparentNavbar && window.addEventListener("scroll", handleTransparency);

  /* TODO: Setup nav items with js file the same as dashboard nav */
  /* TODO: Figure out hash links so we can have nav links to sections of the homepage */
  return (
    <AppBar {...classNames("homepage-navbar", !isTransparent && "solid-nav")}>
      <div className="flex">
        <Link to={RoutesConst.LOGIN} className="logo-link">
          <img src={logoTransparent} alt="Logo" className="navbar-logo" />
          <span className="logo-text">{logoText}</span>
        </Link>
        <div className="nav-link-container flex">
          <Link to={RoutesConst.DEFAULT} className="nav-link">
            {navBarStrings.HOME}
          </Link>
          <Link to={RoutesConst.ABOUT_US} className="nav-link">
            {navBarStrings.ABOUT_US}
          </Link>
          <Link to={RoutesConst.OUR_VISION} className="nav-link">
            {navBarStrings.OUR_VISION}
          </Link>
          <Link to={RoutesConst.EVENTS} className="nav-link">
            {navBarStrings.EVENTS}
          </Link>
          <Link to={RoutesConst.GET_INVOLVED} className="nav-link">
            {navBarStrings.GET_INVOLVED}
          </Link>
          <Link
            to="https://givebutter.com/DbH5hy"
            target="_blank"
            className="nav-link"
          >
            {navBarStrings.DONATE}
          </Link>
          {/* <Link to={RoutesConst.BYLAWS} className="nav-link">
            {navBarStrings.BYLAWS}
          </Link> */}
          <div className="nav-social-links-container">
            <Link
              to="https://www.instagram.com/runningforrealestate1/"
              target="_blank"
              className="nav-social-link"
            >
              <i className="fa-brands fa-instagram social-icon"></i>
            </Link>
            <Link
              to="https://www.facebook.com/running.for.real.estate"
              target="_blank"
              className="nav-social-link"
            >
              <i className="fa-brands fa-facebook-square social-icon"></i>
            </Link>
          </div>
        </div>
        <div className="nav-drawer-toggle">
          <IconButton
            size="medium"
            className="nav-drawer-toggle-button"
            onClick={() => setIsDrawerOpen(true)}
          >
            <i className="fa-solid fa-bars"></i>
          </IconButton>
          <MobileNavDrawer
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
        </div>
      </div>
    </AppBar>
  );
};

export default HomepageNavbar;
