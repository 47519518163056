/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import EndpointsConst from "../consts/EndpointsConst";
import { getAPIUrl } from "../utilities/APIHelper";

class BoardMembersService {
  /**
   * [POST]
   * Add board member to database
   * @param {String} firstName
   * @param {String} lastName
   * @param {String} position
   * @param {String} phoneNumber
   * @param {String} email
   * @param {String} imgLoc
   * @returns {Promise}
   */
  addBoardMember({
    firstName,
    lastName,
    position,
    phoneNumber,
    email,
    imgLoc,
  }) {
    return axios({
      method: "POST",
      url: getAPIUrl() + EndpointsConst.API.BOARD_MEMBERS.ADD_BOARD_MEMBER,
      headers: {},
      data: { firstName, lastName, position, phoneNumber, email, imgLoc },
    });
  }

  /**
   * [DELETE]
   * Deletes board member from database
   * @param {Number} id
   * @returns {Promise}
   */
  deleteBoardMember(id) {
    return axios({
      method: "DELETE",
      url:
        getAPIUrl() +
        EndpointsConst.API.BOARD_MEMBERS.DELETE_BOARD_MEMBER.replace(
          "{id}",
          id
        ),
      headers: {},
    });
  }

  /**
   * [GET]
   * Retrieves board members
   * @returns {Promise}
   */
  getBoardMembers() {
    return axios({
      method: "GET",
      url: getAPIUrl() + EndpointsConst.API.BOARD_MEMBERS.GET_BOARD_MEMBERS,
      headers: {},
    });
  }
}

export default new BoardMembersService();
