/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import EndpointsConst from "../consts/EndpointsConst";
import { getAPIUrl } from "../utilities/APIHelper";

class BylawsService {
  /**
   * [GET]
   * Retrieves bylaws
   * @returns {Promise}
   */
  getBylaws() {
    return axios({
      method: "GET",
      url: getAPIUrl() + EndpointsConst.API.BYLAWS.GET_BYLAWS,
      headers: {},
    });
  }

  /**
   * [POST]
   * Retrieves bylaws
   * @param {String} content
   * @returns {Promise}
   */
  updateBylaws(content) {
    return axios({
      method: "POST",
      url: getAPIUrl() + EndpointsConst.API.BYLAWS.UPDATE_BYLAWS,
      headers: {},
      data: { id: 1, content },
    });
  }
}

export default new BylawsService();
