/* eslint-disable import/no-anonymous-default-export */
export default {
  // TODO: See if we can remove paramaterized queries and pass values in object body
  API: {
    AVA: {
      ADD_SITE_VIEW: "/ava/AddSiteView?domain={domain}",
      GET_SITE_VIEW_COUNT: "/ava/GetSiteViewCount?domain={domain}",
      SUBMIT_CONTACT_FORM: "/ava/SubmitContactForm",
      SUBMIT_HELP_REQUEST: "/ava/SubmitHelpRequest",
    },
    BOARD_MEMBERS: {
      ADD_BOARD_MEMBER: "/boardmembers/AddBoardMember",
      DELETE_BOARD_MEMBER: "/boardmembers/DeleteBoardMember?id={id}",
      GET_BOARD_MEMBERS: "/boardmembers/GetBoardMembers",
    },
    BYLAWS: {
      GET_BYLAWS: "/bylaws/GetBylaws",
      UPDATE_BYLAWS: "/bylaws/UpdateBylaws",
    },
    USERS: {
      ADD_USER: "/users/AddUser",
      CHANGE_PASSWORD:
        "/users/ChangePassword?email={email}&currentPassword={currentPassword}&newPassword={newPassword}",
      DELETE_USER: "/users/DeleteUser?email={email}",
      GENERATE_TEMPORARY_PASSWORD:
        "/users/GenerateTemporaryPassword?email={email}",
      GET_ALL_USERS: "/users/GetAllUsers",
      GET_TOTAL_USER_COUNT: "/users/GetTotalUserCount",
      GET_USER_BY_EMAIL: "/users/GetUserByEmail?email={email}",
      LOGIN: "/users/Login?email={email}&password={password}",
      RESET_PASSWORD: "/users/ResetPassword?email={email}&password={password}",
      UPDATE_USER_PROFILE:
        "/users/UpdateUserProfile?email={email}&firstName={firstName}&lastName={lastName}&position={position}&profilePic={profilePic}",
    },
  },
};
