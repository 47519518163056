import Alert from "@mui/joy/Alert";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel, { formLabelClasses } from "@mui/joy/FormLabel";
import Stack from "@mui/joy/Stack";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import GeneralConst from "../../consts/GeneralConst.js";
import RoutesConst from "../../consts/RoutesConst.js";
import logo from "../../images/logos/logoTransparent.png";
import UserService from "../../services/UserService.js";
import { login } from "../../slices/userSlice.js";
import AlertStrings from "../../strings/AlertStrings.json";
import GeneralStrings from "../../strings/GeneralStrings.json";
import { getCurrentYear } from "../../utilities/DateHelper.js";
import {
  validateConfirmPassword,
  validatePassword,
} from "../../utilities/ValidationHelper";

const ResetPasswordForm = ({ email }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formStatus, setFormStatus] = useState(null);
  const STATUS_CODES = GeneralConst.STATUS_CODES;

  const resetErrorMessages = () => {
    setErrorMessage(null);
    setFormStatus(null);
  };

  const handleSubmit = (formValues) => {
    setIsLoading(true);
    resetErrorMessages();

    UserService.resetPassword(email, formValues.password)
      .then((response) => {
        const status = response.data.status;
        if (status === STATUS_CODES.ERROR) {
          setErrorMessage(
            response?.data?.statusMessage || AlertStrings.GENERAL_ERROR
          );
          setFormStatus(STATUS_CODES.ERROR);
        } else {
          dispatch(
            login({
              email: formValues.email,
            })
          );
          navigate(RoutesConst.DASHBOARD.DEFAULT);
        }
      })
      .catch((response) => {
        setErrorMessage(response?.message || AlertStrings.GENERAL_ERROR);
        setFormStatus(STATUS_CODES.ERROR);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box
      sx={(theme) => ({
        width:
          "clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)",
        transition: "width var(--Transition-duration)",
        transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
        position: "relative",
        zIndex: 1,
        display: "flex",
        justifyContent: "flex-end",
        backdropFilter: "blur(12px)",
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100dvh",
          width:
            "clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)",
          maxWidth: "100%",
          px: 2,
        }}
      >
        <Box
          component="header"
          sx={{
            py: 3,
            display: "flex",
            alignItems: "left",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ gap: 2, display: "flex", alignItems: "center" }}>
            <RouterLink exact to={RoutesConst.DEFAULT} className="logo-link">
              <img src={logo} className="logo" alt={GeneralConst.SITE_NAME} />
              <span>{GeneralConst.SITE_NAME}</span>
            </RouterLink>
          </Box>
        </Box>
        <Box
          component="main"
          sx={{
            my: "auto",
            py: 2,
            pb: 5,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: 400,
            maxWidth: "100%",
            mx: "auto",
            borderRadius: "sm",
            "& form": {
              display: "flex",
              flexDirection: "column",
              gap: 2,
            },
            [`& .${formLabelClasses.asterisk}`]: {
              visibility: "hidden",
            },
          }}
        >
          <Stack gap={4} sx={{ mb: 2 }}>
            <Stack gap={1}>
              <h3>{GeneralStrings.FORM.RESET_PASSWORD}</h3>
              <p>{GeneralStrings.FORM.RESET_PASSWORD_DESCRIPTION}</p>
            </Stack>
          </Stack>

          {formStatus === STATUS_CODES.ERROR && (
            <Alert
              startDecorator={<i className="fa-solid fa-circle-exclamation" />}
              variant="solid"
              color="danger"
              size="lg"
            >
              {errorMessage}
            </Alert>
          )}

          <Stack gap={4} sx={{ mt: 2 }}>
            <Formik
              initialValues={{
                confirmPassword: null,
                email: email,
                password: null,
              }}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ values }) => (
                <Form>
                  <FormControl required>
                    <FormLabel>{GeneralStrings.FORM.NEW_PASSWORD}</FormLabel>
                    <Field
                      name="password"
                      className="form-control"
                      type="password"
                      validate={validatePassword}
                    />
                    <ErrorMessage
                      component="span"
                      className="form-error"
                      name="password"
                    />
                  </FormControl>
                  <FormControl required>
                    <FormLabel>
                      {GeneralStrings.FORM.CONFIRM_PASSWORD}
                    </FormLabel>
                    <Field
                      name="confirmPassword"
                      className="form-control"
                      type="password"
                      validate={(value) =>
                        validateConfirmPassword(values.password, value)
                      }
                    />
                    <ErrorMessage
                      component="span"
                      className="form-error"
                      name="confirmPassword"
                    />
                  </FormControl>
                  <Stack gap={4} sx={{ mt: 2 }}>
                    <Button
                      type="submit"
                      className="btn-primary"
                      disabled={isLoading}
                      fullWidth
                    >
                      {GeneralStrings.FORM.SUBMIT}
                      {isLoading && (
                        <Spinner
                          color="light"
                          size="sm"
                          className="margin-left-10"
                        />
                      )}
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Stack>
        </Box>
        <Box component="footer" sx={{ py: 3 }}>
          <div className="text-center fs-12">
            <span> © {getCurrentYear()} </span>
            <span
              dangerouslySetInnerHTML={{ __html: GeneralStrings.FOOTER_HTML }}
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPasswordForm;
